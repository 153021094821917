<template>
  <v-icon
    v-if="$portal.user.online"
    color="green"
    size="small"
  >
    mdi-account
  </v-icon>
      
  <v-icon
    v-else
    color="red"
    size="small"
  >
    mdi-account-off
  </v-icon>
</template>

<script setup></script>