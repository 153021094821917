<template>
  <div>

    <v-text-field
      v-model="search"
      variant="solo-filled"
      density="compact"
      clearable
      flat
      tile
      hide-details
      autofocus
      placeholder="search..."
      @keyup.esc="search = null"
    >
      <template #prepend-inner>
        <v-icon size="small">
          mdi-magnify
        </v-icon>
      </template>
    </v-text-field>

    <v-list nav density="compact">

      <div
        v-for="section in filteredSections"
        :key="section"
      >

        <v-list-subheader>{{ section }}</v-list-subheader>

        <v-list-item 
          v-for="item in itemsFor(section)"
          :key="item.name"
          v-tooltip="{ text: item.description || item.title, openDelay: 350 }"
          :title="item.title"
          density="compact"
          size="small"
          v-bind="linkProps(item)"
        >
          <template #prepend>
            <v-icon size="small">
              {{ item.icon }}
            </v-icon>
          </template>
          <template #append>
            <v-icon
              v-if="isExternal(item)"
              size="x-small"
            >
              mdi-open-in-new
            </v-icon>
          </template>
        </v-list-item>

      </div>

    </v-list>

  </div>
</template>

<script setup>

const portal = usePortalStore();

const search = ref(null);
const items = computed(() => portal.applications);
const filteredItems = computed(() => search.value ? items.value.filter(e => matchItem(e, search.value)) : items.value);
const filteredSections = computed(() => [...new Set(filteredItems.value.map(e => e.section).filter(e => e))]);

function itemsFor(section) {
  return filteredItems.value.filter(e => e.section == section);
}

function matchItem(item, search) {
  return `${item.title} ${item.description} ${item.section}`.toLowerCase().includes(search.toLowerCase());
}

function isExternal(item) {
  const path = item.landing_page || item.path;
  return path.includes('://');
}

function linkProps(item) {
  const path = item.landing_page || item.path;
  const external = isExternal(item);
  const propName = external ? 'href' : 'to'
  const props = {
    [propName]: path,
  }
  if (external) props.target = '_blank';
  return props;
}

</script>
