<template>

  <portal-loading v-if="! portal.initialised" />

  <portal-maintenance v-else-if="portal.config.maintenance_mode" />

  <v-app v-else :key="reloadKey">

    <v-app-bar color="#b10333">

      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
      />

      <v-btn
        to="/"
        icon="mdi-home"
      />

      <v-app-bar-title>
        Divinity Portal
      </v-app-bar-title>

      <v-spacer />

      <template v-if="portal.admin">

        <template v-if="! portal.original_user">
          <v-text-field
            v-model="portal.impersonateUsername"
            placeholder="Impersonate Username"
            variant="underlined"
          />
          <v-btn
            size="x-small"
            variant="elevated"
            @click="portal.impersonate()"
          >
            Impersonate
          </v-btn>

        </template>

        <template v-else>
          *** Impersonating {{ portal.impersonateUsername }} ***
          <v-btn 
            class="ml-2"
            size="x-small"
            variant="elevated"
            @click="portal.stopImpersonating()"
          >
            Stop
          </v-btn>
        </template>
          
        <v-spacer />
      </template>

      <v-checkbox
        v-if="portal.admin"
        v-model="portal.debug"
        label="debug"
      />

      <!-- <v-btn disabled icon="mdi-magnify" /> -->
      <notifications-icon />
      <!-- <v-btn disabled icon="mdi-dots-vertical" /> -->

    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      temporary
      theme="dark"
    >
      <side-menu />
    </v-navigation-drawer>

    <v-main>

      <application-breadcrumbs margin="1px" />
      <debug title="portal.config">
        {{ $portal.config }}
      </debug>
      <debug title="user">
        {{ $portal.user }}
      </debug>
      <debug title="all roles">
        {{ $portal.roles }}
      </debug>

      <slot />

    </v-main>

    <!-- TODO: built-in help/bug report -->

    <v-footer
      app
      class="bg-black"
    >

      <presence-icon />
            
      <small>Hello, {{ firstName }}!</small>
            
      <v-spacer />

      <small v-if="portal.narrative">
        <v-progress-circular
          indeterminate
          size="15"
          width="1"
        />
        {{ portal.narrative }}
      </small>

      <v-spacer />
            
      <small>University of Divinity</small>

    </v-footer>

  </v-app>

</template>

<script setup>

const portal = usePortalStore();

const drawer = ref();
const firstName = computed(() => usePortalStore().user?.first_name);
const reloadKey = ref(0);

portal.$onAction(({name, after}) => {
  if (name == 'impersonate' || name == 'stopImpersonating') {
    after(() => reloadKey.value++);
  }
})

</script>