<template>
  <span>

    <debug title="toast.ids" raw>{{ messages.clearable }}</debug>
    <debug title="application" raw>{{ portal.application?.title }}</debug>
    <debug title="crumbs">{{ crumbs }}</debug>

    <v-chip
      size="small"
      label
      link
      to="/"
      :style="`margin-right: ${margin};`"
    >
      <v-icon>mdi-home</v-icon>    
      Portal
    </v-chip>

    <v-chip
      v-for="app in crumbs" 
      :key="app.path"
      size="small"
      label
      link
      :closable="! currentApp(app)"
      :to="app.path"
      :style="`margin-right: ${margin};`"
      @click:close.stop.prevent="breadcrumbs.remove(app)"
    >
      <v-icon v-if="withChevrons">mdi-chevron-double-right</v-icon>
      <v-icon>{{ app.icon }}</v-icon>
      {{ app.title || app.name }}
    </v-chip>

    <v-icon v-if="breadcrumbs.crumbs?.length >= clearableCount" @click="breadcrumbs.clear()">mdi-delete</v-icon>

  </span>
</template>

<script setup>

const breadcrumbs = useBreadcrumbsStore();
const messages = useMessageStore();
const portal = usePortalStore();

defineProps({
  withChevrons: {
    type: Boolean,
    required: false,
    default: false
  },
  clearableCount: {
    type: Number,
    required: false,
    default: 5
  },
  margin: {
    type: String,
    required: false,
    default: "2px"
  }
})

const crumbs = computed(() => breadcrumbs.crumbs);

function currentApp(link) {
    return link.path == useRoute().path;
}

</script>