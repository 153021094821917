<template>
  <v-app>
    <v-main class="d-flex bg-grey justify-center align-center">
      <v-card>
        <v-card-text>
          <v-progress-circular indeterminate />
          Loading your portal...
        </v-card-text>
      </v-card>
    </v-main>
  </v-app>
</template>