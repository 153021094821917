<template>
  <v-card class="mx-auto" width="400px">

    <div class="d-flex align-center pl-4 pt-2">
      <v-list-subheader>Notifications</v-list-subheader>
      <v-spacer />
      <v-btn
        size="small"
        variant="text"
        tile
        @click="clear"
      >
        Clear All
      </v-btn>
    </div>

    <v-list lines="two">

      <template v-for="(item, index) in notifications" :key="index">

        <v-divider /> 

        <v-list-item>

          <template #subtitle>
            {{ item.message }}
            <small>#{{ item.id }}</small>
          </template>

          <template #title>
            {{ item.title }}
            <v-chip
              v-if="item.urgent"
              tile
              size="x-small"
              color="warning"
            >
              URGENT
            </v-chip>
          </template>

          <template #prepend>
            <v-icon :color="item.icon_color || item.urgent ? 'warning' : undefined">
              {{ item.icon || item.urgent ? 'mdi-alert' : 'mdi-information-outline' }}
            </v-icon>
          </template>

          <template #append>
            <v-icon
              size="x-small"
              @click.stop.prevent="remove(item)"
            >
              mdi-close
            </v-icon>
          </template>
        
          <v-btn 
            v-for="action in item.data?.actions"
            :key="action.key"
            size="x-small"
            variant="tonal"
            tile
            :to="action.link"
          >
            {{ action.title }}
          </v-btn>
        </v-list-item>

      </template>

      <v-list-item v-if="hasMore">
        <v-divider /> 
        <v-list-item-subtitle class="mt-2">
          <v-chip to="/notifications">
            View {{ moreCount }} more...
          </v-chip>
        </v-list-item-subtitle>
      </v-list-item>

    </v-list>
  </v-card>
</template>

<script setup>

const portal = usePortalStore();

const props = defineProps({
  max: {
    type: Number,
    default: 5
  }
});

const notifications = computed(() => portal.limitedNotifications(props.max));
const totalCount = computed(() => portal.notifications?.length);
const hasMore = computed(() => totalCount.value > props.max);
const moreCount = computed(() => totalCount.value - notifications.value.length);

function clear() {
  portal.clearNotifications();
}

function remove(item) {
  const index = portal.notifications.indexOf(item);
  if (index !== -1) {
    portal.notifications.splice(index, 1);
  }
}

</script>