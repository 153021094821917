<template>

  <v-btn class="text-none pa-0 ma-0" stacked>
    <v-badge 
      v-if="notifications.length > 0"
      color="red"
      max="10"
      :content="notifications.length"
    >
      <v-icon>mdi-bell</v-icon>
    </v-badge>

    <v-icon v-else>
      mdi-bell
    </v-icon>

    <v-menu
      v-model="showing"
      activator="parent"
      :disabled="notifications.length < 1"
      transition="slide-y-transition"
      location="bottom"
    >
      <notifications-menu :max="max" />
    </v-menu>
      
  </v-btn>

</template>

<script setup>

import { whenever } from '@vueuse/core';

const portal = usePortalStore();

const props = defineProps({
  max: {
    type: Number,
    default: 5
  }
});

const notifications = computed(() => portal.limitedNotifications(props.max));
const hasUrgentNotifications = computed(() => portal.notifications?.some(n => n.urgent));
const showing = ref(false);

// FIXME: doesn't work after the first time
whenever(hasUrgentNotifications, () => showing.value = true, { immediate: true });

</script>