<template>
  <v-card flat>
    <v-card-title>
      Maintenance
    </v-card-title>
    <v-card-text>
      {{ $portal.config.maintenance_message }}
    </v-card-text>
  </v-card>
</template>

<script setup>
</script>